import React from "react"
import CookieConsent from "react-cookie-consent"

export default function() {
  return (
    <CookieConsent
      buttonText="Agree"
      buttonStyle={{ backgroundColor: "#ffc20a", fontSize: "15px", borderRadius: "5px", padding: '5px 20px', color: '#475059', fontWeight: 'bold' }}
      style={{ textDecoration: "none!important", }}>
      We use browser cookies to personalize content and ads, to provide social media features and analyse traffic. To use our site, you must agree to our <u><a className="py-2 text-contrast" href="/privacy-policy">Privacy Policy</a></u>.
    </CookieConsent>
  );
}
