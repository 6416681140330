import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from 'gatsby';
import {Nav, Row, Col} from "reactstrap";
import Container from "../container/Container";
import "./style.css";
import LogoWhite from "../navigator/logo/logo-white.png"

const DefaultFooter = () => {
  return (
    <footer className="footer-section">
      <Container size="xsmall">
        <Row className="row gap-y text-left">
          <Col md="3" lg="4" className="mr-auto text-center text-lg-left">
	          <div className="logo">
              <Link to="/">
                <img src={ LogoWhite } alt="Mockless.com" width="180px" />
              </Link>
	          </div>
          </Col>

	        <Col md="6">
		        <Row>
			        <Col md="4">
				        <Nav className="flex-column" tag="nav">
					        <Link className="py-2 text-contrast text-center text-lg-left" to="/terms">
						        Terms & conditions
					        </Link>
				        </Nav>
			        </Col>

			        <Col md="4">
				        <Nav className="flex-column" tag="nav">
					        <Link className="py-2 text-contrast text-center text-lg-left" to="/privacy-policy">
						        Privacy policy
					        </Link>
				        </Nav>
			        </Col>

			        <Col md="4">
				        <Nav className="flex-column" tag="nav">
					        <Link className="py-2 text-contrast text-center text-lg-left mb-4 mb-lg-0" to="/contact">
						        Contact
					        </Link>
				        </Nav>
			        </Col>
		        </Row>
	        </Col>

          <Col md="3" lg="2">

            <nav className="nav justify-content-around">
              <a
                href="https://www.facebook.com/app.mockless/"
                target="_blank"
                rel="noopener noreferrer nofollow"
                className="btn btn-circle btn-sm brand-facebook"
              >
                <FontAwesomeIcon icon={["fab", "facebook"]} />
              </a>
              <a href="https://twitter.com/Mockless1"
                 target="_blank"
                 rel="noopener noreferrer nofollow"
                 className="btn btn-circle btn-sm brand-twitter">
                <FontAwesomeIcon icon={["fab", "twitter"]} />
              </a>
              <a
	              href="https://www.linkedin.com/company/30622899"
	              target="_blank"
	              rel="noopener noreferrer nofollow"
	              className="btn btn-circle btn-sm brand-linkedin">
                <FontAwesomeIcon icon={["fab", "linkedin"]} />
              </a>
            </nav>
          </Col>
        </Row>

        <hr className="mt-5 op-5" />

        <Row className="small">
          <Col md="12">
            <p className="mt-2 mb-0 text-center text-md-left">
              © {new Date().getFullYear()} mockless.com. All Rights Reserved
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default DefaultFooter;
